<template>
  <vue-element-loading :active="!appReady" is-full-screen :text="`${randomLoadingText} ....`"></vue-element-loading>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppLoader",
  data() {
    return {
      loadingText: [
        "Stacking cheddar",
        "Cashing out",
        "Grabbing loot",
        "Scoring coin",
        "Making moolah",
        "Pulling in dough",
        "Bagging green",
        "Raking in loot",
        "Filling the wallet",
        "Fetching cash",
      ],
    };
  },
  computed: {
    randomLoadingText() {
      return this.loadingText[Math.floor(Math.random() * this.loadingText.length)];
    },
    appReady() {
      return !!this.user;
    },
    ...mapState("auth", ["user"]),
  },
};
</script>
