<template>
  <div>
    <app-loader></app-loader>
    <layout-horizontal>
      <router-view />
    </layout-horizontal>
  </div>
</template>

<script>
import LayoutHorizontal from "@core/layouts/layout-horizontal/LayoutHorizontal.vue";
import AppLoader from "@/components/AppLoader.vue";

export default {
  components: {
    LayoutHorizontal,
    AppLoader,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
